<template>
  <div class="justify-center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <div>
          <v-row class="justify-end">
            <v-btn icon color="primary" @click="$emit('closeDialog')">
              <v-icon v-text="'close'" />
            </v-btn>
          </v-row>
          <div>
            <v-row>
              <v-icon x-large color="primary" v-text="'lightbulb_outline'" />
            </v-row>
            <p class="mt-6" v-text="$t('project.enrollments.requestPaymentEnrollmentDialog.infoDiscount')" />
            <v-radio-group v-model="form.discountType" class="mt-4">
              <v-row>
                <v-col cols="1" sm="1" class="pl-0 pl-sm-3 mt-2">
                  <v-radio value="percent" />
                </v-col>
                <v-col cols="9" sm="10" class="pl-sm-0">
                  <v-text-field
                    v-model.number="form.discountPercent" type="number" min="1" outlined dense
                    :label="`${$t('project.enrollments.requestPaymentEnrollmentDialog.discountPercent')} ${$t('common.optional')}`"
                    @keypress="validateKeyNumeric($event)"
                  />
                </v-col>
                <v-col cols="1" sm="1" class="mt-2 pl-0">
                  <global-tooltip
                    :text="$t('project.enrollments.requestPaymentEnrollmentDialog.tooltip.discountPercent')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" sm="1" class="pl-0 pl-sm-3 mt-2">
                  <v-radio value="amount" />
                </v-col>
                <v-col cols="9" sm="10" class="pl-sm-0">
                  <v-text-field
                    v-model.number="form.discountAmount" type="number" min="1" outlined dense
                    :label="`${$t('project.enrollments.requestPaymentEnrollmentDialog.discountAmount')} ${$t('common.optional')}`"
                    @keypress="validateKeyNumeric($event)"
                  />
                </v-col>
                <v-col cols="1" sm="1" class="mt-2 pl-0">
                  <global-tooltip
                    :text="$t('project.enrollments.requestPaymentEnrollmentDialog.tooltip.discountAmount')"
                  />
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary" min-width="12rem"
              @click="$emit('requestEnrollment',
                            {
                              type: form.discountType,
                              value: form.discountType === 'percent' ? form.discountPercent : form.discountAmount
                            }
              )"
              v-text="$t('project.enrollments.requestPaymentEnrollmentDialog.requestPayment')"
            />
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'RequestPaymentEnrollment',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils],
  data: () => ({ form: { discountType: 'percent', discountPercent: null, discountAmount: null } }),
}
</script>
